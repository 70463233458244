<template>
    <div>



        <v-app>

            <v-main>

                <div class="my-4 d-flex justify-space-between ">
                    <div class="d-flex align-center">
                        <v-icon size="50" class="white--text">$vuetify.icons.pj</v-icon>
                        <v-divider vertical class="mx-4 grey"></v-divider>
                        <span class=" text-uppercase font-weight-light title">
                            Simulador de escenarios
                        </span>
                    </div>

                </div>
                <v-container fluid>
                    <v-row>
                        <v-col>
                            <div class=" text-h6">
                                Simulaci&oacute;n: {{ data.Nombre }}
                            </div>

                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pa-1">

                            <v-card outlined>
                                <v-card-title class="pb-1 px-2 text-subtitle-1 blue--text text--darken-1">
                                    Electores/as
                                </v-card-title>
                                <v-card-text class=" text-h5 font-weight-bold">
                                    {{ formatelectores(totalElectores) }}
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col class="pa-1">
                            <v-card outlined>
                                <v-card-title class="pb-1 px-2 text-subtitle-1 blue--text text--darken-1">
                                    Participaci&oacute;n
                                </v-card-title>
                                <v-card-text class=" text-h5 font-weight-bold">
                                    {{ formatpercent(participacion) }}%
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col class="pa-1">
                            <v-card outlined>
                                <v-card-title class="pb-1 px-2 text-subtitle-1 blue--text text--darken-1">
                                    Electores sobre participaci&oacute;n
                                </v-card-title>
                                <v-card-text class=" text-h5 font-weight-bold">
                                    {{ formatelectores(electoresPorParticipacion) }}
                                </v-card-text>
                            </v-card>
                        </v-col>

                    </v-row>
                </v-container>

                <v-container fluid>

                    <v-row>

                        <v-col class="pa-1">
                            <v-card outlined>
                                <v-card-title class="pb-1 px-2 text-subtitle-1 blue--text text--darken-1">
                                    <div style=" width: 50%;">
                                       
                                        <div class=" text-center font-weight-bold text-h4 grey--text text--darken-2">
                                            CFK
                                        </div>
                                        <div class=" text-center font-weight-bold text-h4 blue--text text--lighten-1">{{
                                            porcentageCandidato1.toFixed(2) }}%
                                        </div>
                                    </div>
                                    <v-divider vertical></v-divider>
                                    <div style=" width: 50%;">
                                        <div class=" text-center font-weight-bold text-h4 grey--text text--darken-2">
                                            QUINTELA
                                        </div>

                                        <div class=" text-center font-weight-bold text-h4 green--text text--lighten-1">
                                            {{
                                                porcentageCandidato2.toFixed(2) }}%</div>


                                    </div>
                                </v-card-title>

                                <v-card-actions>
                                    <v-progress-linear class=" rounded" background-color="green lighten-1"
                                        color="blue lighten-1" height="30"
                                        :value="porcentageCandidato1"></v-progress-linear>
                                </v-card-actions>
                            </v-card>
                        </v-col>

                    </v-row>

                    <!-- <div class=" rounded ">

                        <div class=" d-flex    rounded">
                            <div style=" width: 50%;">
                                <div class=" text-center font-weight-bold text-h4 blue--text text--lighten-1">
                                    CFK
                                </div>
                                <div class=" text-center font-weight-bold text-h4 blue--text text--lighten-1">{{
                                    porcentageCandidato1.toFixed(2) }}%
                                </div>
                            </div>
                            <v-divider vertical></v-divider>
                            <div style=" width: 50%;">
                                <div class=" text-center font-weight-bold text-h4 green--text text--lighten-1">
                                    QUINTELA
                                </div>

                                <div class=" text-center font-weight-bold text-h4 green--text text--lighten-1">{{
                                    porcentageCandidato2.toFixed(2) }}%</div>


                            </div>
                        </div>
                        <div>
                            <v-progress-linear class=" rounded" background-color="green lighten-1"
                                color="blue lighten-1" height="25" :value="porcentageCandidato1"></v-progress-linear>
                        </div>
                    </div> -->
                </v-container>

                <v-container fluid>



                    <v-row>
                        <v-col class="text-caption">

                            <v-simple-table dense class="informetable">
                                <thead>
                                    <tr>
                                        <th class="text-left px-1 font-weight-regular" :rowspan="2" width="30%">Distrito
                                        </th>
                                        <th class="text-left px-1 font-weight-regular" :rowspan="2" width="10%">
                                            Electores</th>
                                        <th class="text-left px-1 font-weight-regular" :rowspan="2" width="10%">
                                            Participación</th>
                                        <th class="text-left px-1 font-weight-regular" :rowspan="2" width="10%">
                                            Electores s/
                                            participación</th>
                                        <!-- Encabezado "Resultados" que abarca 4 columnas -->
                                        <th class="text-center" colspan="4" width="40%">Resultados</th>
                                    </tr>
                                    <!-- Segunda fila con sub-cabeceras dentro de las 4 columnas de "Resultados" -->
                                    <tr>

                                        <th colspan="2" class="text-center">CFK</th>
                                        <th colspan="2" class="text-center">Quintela</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="(d, index) in data.Distribucion" :key="index" class="  "
                                        :class="{ 'grey lighten-3  ': (index % 2 == 0) }">
                                        <td class="px-1 pb-1 font-weight-bold  text-capitalize  "
                                            style="max-width: 120px;">{{ d.Distrito.toLowerCase() }}</td>
                                        <td class="px-1 text-no-wrap">{{ formatelectores(d.Electores) }}</td>
                                        <td class="px-1 text-no-wrap">{{ formatpercent(d.Participacion) }} %</td>
                                        <td class="px-1 text-no-wrap">{{ formatelectores(
                                            parseFloat(d.ElectoresPorParticipacion)) }}
                                        </td>
                                        <td class="px-1 text-no-wrap" width="10%">{{
                                            formatpercent(d.Fuerzas[0].PorcentajeSimulacion)
                                        }}%</td>
                                        <td class="px-1 text-no-wrap" width="10%">{{
                                            formatelectores(d.Fuerzas[0].Ganadas) }}
                                        </td>
                                        <td class="px-1 text-no-wrap" width="10%">{{
                                            formatpercent(d.Fuerzas[1].PorcentajeSimulacion)
                                        }}%</td>
                                        <td class="px-1 text-no-wrap" width="10%">{{
                                            formatelectores(d.Fuerzas[1].Ganadas) }}
                                        </td>
                                    </tr>


                                    <!-- <tr class="twolines" v-for="(item, index) in distribuciones"
                            :class="{ 'grey lighten-3 ': (index % 2 == 0) }"
                            :key="item.Electores">
                            <td class="px-1 pb-1 font-weight-bold  text-capitalize  "
                                style="max-width: 120px;">{{ item.Distrito.toLowerCase() }}</td>
                            <td class="px-1 text-no-wrap">{{ item.Electores }}</td>
                            <td class="px-1 text-no-wrap">{{ item.Participacion }} %</td>
                            <td class="px-1 text-no-wrap">{{ item.Blancos }} %</td>
                            <td class="px-1 text-no-wrap">{{ item.Nulos }} %</td>
                            <td class="px-1 text-no-wrap">{{ item.Fuerzas[0].Porcentaje }} %
                            </td>
                            <td class="px-1 text-no-wrap">{{ item.Fuerzas[1].Porcentaje }} %
                            </td>
                            <td class="px-1 text-no-wrap">{{ item.Fuerzas[2].Porcentaje }} %
                            </td>
                            <td class="px-1 text-no-wrap">{{ item.Fuerzas[3].Porcentaje }} %
                            </td>
                            <td class="px-1 text-no-wrap font-weight-bold">
                                <div class="text-center pb-1">

                                    {{ obtenidosDistrito(item.Fuerzas[0]) }}
                                    <span>(</span>
                                    <span v-if="(ganadosDistrito(item.Fuerzas[0])) > 0"
                                        class=" light-green--text">
                                        {{ ganadosDistrito(item.Fuerzas[0]) }}↑
                                    </span>
                                    <span v-else-if="(ganadosDistrito(item.Fuerzas[0])) == 0"
                                        class=" lime--text">
                                        {{ ganadosDistrito(item.Fuerzas[0]) }}=
                                    </span>
                                    <span v-else class=" error--text">
                                        {{ ganadosDistrito(item.Fuerzas[0]) }}↓
                                    </span>
                                    <span>)</span>
                                </div>
                                <div class=" grey--text text--lighten-3 text-center text-uppercase font-weight-regular"
                                    :class="((ganadosDistrito(item.Fuerzas[0])) >= 0) ? (ganadosDistrito(item.Fuerzas[0]) == 0) ? 'lime' : 'light-green' : 'error'">
                                    total:{{ totalDistrito(item.Fuerzas[0]) }}
                                </div>

                            </td>
                            <td class="px-1 text-no-wrap font-weight-bold">
                                <div class="text-center pb-1">

                                    {{ obtenidosDistrito(item.Fuerzas[1]) }}
                                    <span>(</span>
                                    <span v-if="(ganadosDistrito(item.Fuerzas[1])) > 0"
                                        class=" light-green--text">
                                        {{ ganadosDistrito(item.Fuerzas[1]) }}↑
                                    </span>
                                    <span v-else-if="(ganadosDistrito(item.Fuerzas[1])) == 0"
                                        class=" lime--text">
                                        {{ ganadosDistrito(item.Fuerzas[1]) }}=
                                    </span>
                                    <span v-else class=" error--text">
                                        {{ ganadosDistrito(item.Fuerzas[1]) }}↓
                                    </span>
                                    <span>)</span>
                                </div>
                                <div class=" grey--text text--lighten-3 text-center text-uppercase font-weight-regular"
                                    :class="((ganadosDistrito(item.Fuerzas[1])) >= 0) ? (ganadosDistrito(item.Fuerzas[1]) == 0) ? 'lime' : 'light-green' : 'error'">
                                    total:{{ totalDistrito(item.Fuerzas[1]) }}
                                </div>
                            </td>
                            <td class="px-1 text-no-wrap font-weight-bold">
                                <div class="text-center pb-1">

                                    {{ obtenidosDistrito(item.Fuerzas[2]) }}
                                    <span>(</span>
                                    <span v-if="(ganadosDistrito(item.Fuerzas[2])) > 0"
                                        class=" light-green--text">
                                        {{ ganadosDistrito(item.Fuerzas[2]) }} ↑
                                    </span>
                                    <span v-else-if="(ganadosDistrito(item.Fuerzas[2])) == 0"
                                        class=" lime--text">
                                        {{ ganadosDistrito(item.Fuerzas[2]) }}=
                                    </span>
                                    <span v-else class=" error--text">
                                        {{ ganadosDistrito(item.Fuerzas[2]) }}↓
                                    </span>
                                    <span>)</span>
                                </div>
                                <div
                                    class=" grey--text text--lighten-3 text-center text-uppercase font-weight-regular lime">
                                    total:{{ totalDistrito(item.Fuerzas[2]) }}
                                </div>
                            </td>
                            <td class="px-1 text-no-wrap font-weight-bold">
                                <div class="text-center pb-1">

                                    {{ obtenidosDistrito(item.Fuerzas[3]) }}
                                    <span>(</span>
                                    <span v-if="(ganadosDistrito(item.Fuerzas[3])) > 0"
                                        class=" light-green--text">
                                        {{ ganadosDistrito(item.Fuerzas[3]) }}↑
                                    </span>
                                    <span v-else-if="(ganadosDistrito(item.Fuerzas[3])) == 0"
                                        class=" lime--text">
                                        {{ ganadosDistrito(item.Fuerzas[3]) }}=
                                    </span>
                                    <span v-else class=" error--text">
                                        {{ ganadosDistrito(item.Fuerzas[3]) }}↓
                                    </span>
                                    <span>)</span>
                                </div>
                                <div
                                    class="lime grey--text text--lighten-3 text-center text-uppercase font-weight-regular">
                                    total:{{ totalDistrito(item.Fuerzas[3]) }}
                                </div>
                            </td>
                        </tr> -->
                                </tbody>
                            </v-simple-table>


                        </v-col>
                    </v-row>
                </v-container>

            </v-main>
        </v-app>








    </div>
</template>

<script>

// import VueHtml2pdf from 'vue-html2pdf'
// import Camara from '../simulacion/camara.vue'
// import Candidatos from '@/components/Candidatos.vue';
import { mapActions, mapState, mapGetters } from 'vuex'
// import Header from '@/components/dashboard/Header.vue';


export default {
    props: {

        camaras: {
            type: Array
        }
    },
    components: {
        // VueHtml2pdf,
        // Camara
        // Header
        // Candidatos
    },

    created() {

        // console.log(this.data);

        this.$nextTick(() => {
            window.print()
        })
    },



    data: () => ({
    }),

    methods: {


        /*
            Generate Report using refs and calling the
            refs function generatePdf()
        */
        generateReport() {

            // this.$refs.camaraActual.dale()
            // this.$refs.camaraProyeccion.dale()

            window.print()
            // this.$refs.html2Pdf.generatePdf()


        },

        onProgress($event) {

            if ($event == 100) {
                this.$emit('done', true)
            }
        },

        hasStartedGeneration() {
            console.log("hasStartedGeneration");
        },

        hasGenerated($event) {
            console.log("hasGenerated", $event);
        },

        venceMandato(agrupacion) {

            let res = this.distribuciones.map(agrupaciondistribucion => agrupaciondistribucion.Fuerzas.find(fuerza => fuerza.Fuerza == agrupacion))
            return res.reduce((cantidadAcumulador, cantidad) => cantidadAcumulador + cantidad.Renueva, 0)
        },

        obtenidos(agrupacion) {

            let res = this.distribuciones.map(agrupaciondistribucion => agrupaciondistribucion.Fuerzas.find(fuerza => fuerza.Fuerza == agrupacion))
            return res.reduce((cantidadAcumulador, cantidad) => cantidadAcumulador + cantidad.Ganadas, 0)
        },

        venceMandatoDistrito(fuerza) {

            return fuerza.Renueva

            // let res = this.distribuciones.map(agrupaciondistribucion => agrupaciondistribucion.Fuerzas.find(fuerza => fuerza.Fuerza == agrupacion))
            // return res.reduce((cantidadAcumulador, cantidad) => cantidadAcumulador + cantidad.Renueva, 0)
        },

        obtenidosDistrito(fuerza) {

            return fuerza.Ganadas
            // let res = this.distribuciones.map(agrupaciondistribucion => agrupaciondistribucion.Fuerzas.find(fuerza => fuerza.Fuerza == agrupacion))
            // return res.reduce((cantidadAcumulador, cantidad) => cantidadAcumulador + cantidad.Ganadas, 0)
        },

        ganadosDistrito(fuerza) {
            // console.log(fuerza);
            return this.obtenidosDistrito(fuerza) - this.venceMandatoDistrito(fuerza)
        },

        totalDistrito(fuerza) {
            // console.log(fuerza);
            return fuerza.Bancas - fuerza.Renueva + fuerza.Ganadas
        },

        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },


        formatpercent(number) {
            // Convertimos la cadena a un número con decimales (si corresponde)
            return number.toLocaleString('es-AR', {
                minimumFractionDigits: 0, // Aseguramos siempre 2 decimales
                maximumFractionDigits: 2  // Máximo de 2 decimales
            });
        },
        formatelectores(number) {
            // Convertimos la cadena a un número con decimales (si corresponde)
            return number.toLocaleString('es-AR', {
                minimumFractionDigits: 0, // Aseguramos siempre 2 decimales
                maximumFractionDigits: 0  // Máximo de 2 decimales
            });
        },


    },

    computed: {


        candidato1() {
            return this.data.Distribucion.map((d) => d.Fuerzas.find((f) => f.Fuerza == "CFK")).flat()
                .reduce((acc, dist) => acc + dist.Ganadas, 0)

        },
        candidato2() {
            return this.data.Distribucion.map((d) => d.Fuerzas.find((f) => f.Fuerza == "QUINTELA")).flat()
                .reduce((acc, dist) => acc + dist.Ganadas, 0)

        },

        porcentageCandidato1() {
            return (this.candidato1 * 100) / (this.candidato1 + this.candidato2)
        },
        porcentageCandidato2() {
            return (this.candidato2 * 100) / (this.candidato1 + this.candidato2)
        },

        electoresPorParticipacion() {

            return this.data.Distribucion.reduce((acum, distribucion) => acum + parseFloat(distribucion.ElectoresPorParticipacion), 0)
        },
        totalElectores() {

            return this.data.Distribucion.reduce((acum, distribucion) => acum + distribucion.Electores, 0)

        },

        participacion() {
            return this.electoresPorParticipacion * 100 / this.totalElectores
        },

        ...mapGetters(['simulacionToPrint']),

        data() {
            return this.simulacionToPrint
        },

        proyeccion() {
            let camara = this.camaras.find(c => this.data.Id == c.value)
            return (camara) ? camara.text : ''
        },

        distribuciones() {
            return this.data.Distribucion.filter((d) => d.Fuerzas.reduce((acc, f) => acc + f.Renueva, 0) > 0)

        },

        cantidadVotosTotales() {
            return (!this.distribuciones.length) ? 0 : this.distribuciones.reduce((acc, cant) => acc + Number(((cant.Electores * cant.Participacion) / 100).toFixed()), 0)
        },

        cantidad() {
            return (!this.distribuciones.length) ? 0 : this.distribuciones.reduce((acc, cant) => acc + Number(((cant.Electores * cant.Participacion) / 100).toFixed()), 0)
        },

        electores() {
            return this.distribuciones.reduce((cantidadAcumulador, cantidad) => cantidadAcumulador + cantidad.Electores, 0)
        },

        // participacion() {
        //     return this.data.Distribucion.reduce((cantidadAcumulador, cantidad) => cantidadAcumulador + cantidad.Participacion, 0)
        // },

        blancos() {
            // console.log(this.distribuciones[0]);
            return this.distribuciones.reduce((acc, d) => acc + Number((Number(((d.Electores * d.Participacion) / 100).toFixed() * d.Blancos) / 100).toFixed()), 0)
        },

        nulos() {
            return this.distribuciones.reduce((acc, d) => acc + Number((Number(((d.Electores * d.Participacion) / 100).toFixed() * d.Nulos) / 100).toFixed()), 0)


        },

        ...mapState([
            'dataResultados',

        ])


    }


    // mounted() {
    //     console.log('mounted');
    //     this.generateReport()
    // }


}
</script>


<style lang="scss">
@media print {
    .no-print {
        display: none;
    }

    /* Asegura que el .only-print se muestre incluso si el padre tiene no-print */
    .no-print .only-print {
        display: block;
    }
}

@media screen {
    .only-print {
        display: none;
    }
}

.twolines {
    height: 2rem;
    line-height: 1em;
}

.informetable {
    font-size: 0.7rem;

    td,
    th,
    .subheaderinforme {
        border: 1px solid #f1efef;
        padding: 0px !important;
        font-size: 0.7rem !important;
    }

    ;

}
</style>

import Vue from 'vue'
import { mapActions, mapState } from "vuex";
import simulacionService from "@/services/SimulacionService";

export default Vue.extend({
  data: () => {
    return {
      itemsPerPageArray: [4, 8, 12],
      search: '',
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 12,
      sortBy: 'Nombre',
      keys: [
        "Ambito",
        "Cargo",
      ],
      items: [],
      dialogDelete: false,
      deleteSimulacion: {
        id: null,
        name: '',
        cargo: '',
        jurisdiccion: ''
      },
      snackbar: {
        active: false,
        text: ''
      }
    }
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage)
    },
    filteredKeys() {
      return this.keys.filter((key: string) => key !== 'Nombre')
    },
  },
  methods: {


    ...mapActions("general", ["showToast", "setNotification"]),
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage(number: number) {
      this.itemsPerPage = number
    },

    showDialogEliminar(sim: any) {
      this.deleteSimulacion.id = sim._id
      this.deleteSimulacion.name = sim.Nombre
      this.deleteSimulacion.cargo = sim.Cargo
      this.deleteSimulacion.jurisdiccion = sim.Jurisdiccion
      this.dialogDelete = true;
    },

    async deleteSim() {
      let result = await simulacionService.delete(this.deleteSimulacion.id)
      if (result) {

        this.items = this.items.filter((i: any) => i._id != this.deleteSimulacion.id)
        if (this.items?.length == 0) {
          this.$router.push("/simulacion/nuevo");
        }
        this.snackbar.text = ' Simulación eliminada correctamente ';
        this.snackbar.active = true;
      }
      this.dialogDelete = false;

    },

    async validate() {
      let result = await this.$refs.usuario.validate();
      return result;
    },
    async initialize() {
      let resu = await simulacionService.all({});
      this.items = resu.data;
      if (this.items?.length == 0) {
        this.$router.push("/simulacion/nuevo");
      }
    },
  },
  created() {
    this.initialize();


  },

})

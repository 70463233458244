import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({

  state: {
    notifications: [],
    token: localStorage.getItem('token') || null,
    rol: localStorage.getItem('rol') || null,
    user: {
      id: localStorage.getItem('id') || null,
      username: localStorage.getItem('username') || null
    },
    dataResultados: [],
    simtoPrint:  JSON.parse(localStorage.getItem('simtoPrint') ?? '{}') 
  },
  getters: {
    isAuthenticated(state) {
      return state.token != null
    },

    simulacionToPrint(state) {
      return state.simtoPrint
    }
  },
  mutations: {

    setSimtoPrint(state, dataResultados: any) {
  
      state.simtoPrint = dataResultados
      localStorage.setItem("simtoPrint", JSON.stringify(dataResultados))

    },

    setData(state, dataResultados: any) {
      if (state.dataResultados.length == 8) {
        state.dataResultados = []
      }
      state.dataResultados = state.dataResultados.concat(dataResultados)
    },
    // eslint-disable-next-line
    SET_NOTIFICATION(state: any, notification: any) {
      state.notifications = state.notifications.concat(notification);
    },
    setToken(state, payload) {
      state.token = payload
      localStorage.setItem("token", payload)
    },
    setUsername(state, payload) {
      state.user.username = payload
      localStorage.setItem("username", payload)
    },
    setRol(state, payload) {
      state.rol = payload
      localStorage.setItem("rol", payload)
    },
    clearToken(state) {
      state.token = null,
      state.user.username = null;
      state.user.id = null;
      state.rol = null,
      localStorage.removeItem("token")
      localStorage.removeItem("rol")
    },

  },
  actions: {

    setSimtoPrint({ commit }, dataResultados) {
      commit('setSimtoPrint', dataResultados)

    },

    setData({ commit }, dataResultados) {
      commit('setData', dataResultados)
    },
    setNotification({ commit }, notification: any) {
      notification.showing = true;
      notification.color = notification.color || 'black';
      commit('SET_NOTIFICATION', notification);
    },
    setToken({ commit }, token) {
      commit('setToken', token)
    },
    setRol({ commit }, rol) {
      commit('setRol', rol)
    },
    setUsername({ commit }, username) {
      commit('setUsername', username)
    },
    clearToken({ commit }) {
      commit('clearToken');
    },
    LOGOUT_REQUEST: ({ state, commit }) => {
      state.user.username = "";
      commit('clearToken');
    },
    LOGOUT_AND_REDIRECT: ({ state, commit }) => {
      state.user.username = "";
      commit('clearToken');
      // this.router.push("/login");
    },
  },
  modules: {
  }
})



import Vue, { readonly } from 'vue'
import { mapActions, mapState } from "vuex";
import simulacionService from "@/services/SimulacionService";
// import { Dhondt } from "dhondt-calculator";
// import informe from "@/views/informe/informe.vue"
import Candidatos from "@/components/Candidatos.vue"
import router from '@/router';

// import Camara from './camara.vue'
export default {
    name: 'simulacion',

    components: {
        // Camara,
        Candidatos,
        // informe
    },
    data: () => ({

        loadReport: false,

        direction: 'top',
        fab: false,
        fling: false,
        hover: false,
        tabs: null,
        top: false,
        right: true,
        bottom: true,
        left: false,
        transition: 'slide-y-reverse-transition',

        participacionBase: 0,
        setCandidatos: false,
        candidatoBase1: 0,
        candidatoBase2: 0,

        dialog: false,
        dialogParticipacion: false,

        reloadCamaras: true,

        filterDistribucion : '',

        simulacion: {
            Id: 0,
            Ambito: "",
            BancasTotales: 0,
            Cargo: "",
            Distribucion: [
                {
                    IdDistrito: 1,
                    Distrito: "",
                    Electores: 0,
                    Participacion: 0,
                    Blancos: 0,
                    Nulos: 0,
                    ElectoresPorParticipacion: 0,
                    Fuerzas: [
                        {
                            Fuerza: "CFK",
                            Bancas: 0,
                            Renueva: 0,
                            Porcentaje: 0,
                            Ganadas: 0,
                            PorcentajeSimulacion: 0

                        },
                        {
                            Fuerza: "QUINTELA",
                            Bancas: 0,
                            Renueva: 0,
                            Porcentaje: 0,
                            Ganadas: 0,
                            PorcentajeSimulacion: 0
                        }
                    ],
                }
            ],
            IdAmbito: 0,
            Jurisdiccion: "",
            Nombre: "",
        },

        simulacionBase: {
            Ambito: "",
            BancasTotales: 0,
            Cargo: "",
            Distribucion: [],
            Jurisdiccion: "",
            Nombre: "",
        },

        camaras: [

            {
                text: 'Provincia Buenos Aires',
                value: 10
            },
            {
                text: 'Nacional por Provincia',
                value: 11
            },
            // {
            //     text: 'Provincia Buenos Aires 100% Participacion',
            //     value: 12
            // },
            // {
            //     text: 'Nacional por Provincia 100% Participacion',
            //     value: 13
            // }
        ],

        viewBtAcction: false,

        modeEdit: false

    }),

    created() {

        if (this.$route.params.idsimulacion.toString() != "nuevo") {
            this.initializeEdit(this.$route.params.idsimulacion.toString());
        } else {
            this.initializeAdd(this.simulacion.Id);
        }
        window.addEventListener('scroll', this.handleScroll);
    },

    methods: {
        ...mapActions(["showToast", "setNotification"]),



        updateInput1(distribucion: any) {
            console.log("🚀 ~ updateInput1 ~ distribucion:", distribucion)
            distribucion.Fuerzas[0].PorcentajeSimulacion = 100 - distribucion.Fuerzas[1].PorcentajeSimulacion

        },
        updateInput2(distribucion: any) {
            console.log("🚀 ~ updateInput2 ~ distribucion:", distribucion)
            distribucion.Fuerzas[1].PorcentajeSimulacion = 100 - distribucion.Fuerzas[0].PorcentajeSimulacion

        },

        updateInput1Base() {
            this.candidatoBase1 = 100 - this.candidatoBase2

        },
        updateInput2Base() {
            this.candidatoBase2 = 100 - this.candidatoBase1


        },

        


        saveConfiguracionBase() {
            let aux = this.simulacion.Distribucion.map((d: any) => {

                let fue = d.Fuerzas.map((f: any) => {

                    let porcent = f.PorcentajeSimulacion
                    if (this.setCandidatos) {
                        porcent = f.Fuerza == "CFK" ? this.candidatoBase1 : this.candidatoBase2
                    }

                    return {
                        ...f,
                        PorcentajeSimulacion: porcent
                    }
                })

                return {
                    ...d,
                    Fuerzas: fue,
                    Participacion: this.participacionBase
                }
            })
            this.$set(this.simulacion, 'Distribucion', aux);
            this.dialogParticipacion = false;
        },

        simulation() {
            let aux = this.simulacion.Distribucion.map((d: any) => {

                let electores = parseFloat(((d.Electores * d.Participacion) / 100).toFixed(0))

                let fue = d.Fuerzas.map((f: any) => {


                    let Ganadas = ((electores * parseFloat(f.PorcentajeSimulacion)) / 100)

                    console.log("🚀 ~ Ganadas ~ Ganadas:", Ganadas)
                    return {
                        ...f,
                        Ganadas: Ganadas
                    }
                })

                return {
                    ...d,
                    ElectoresPorParticipacion: ((d.Electores * d.Participacion) / 100).toFixed(0),
                    Fuerzas: fue

                }
            }
            )
            this.$set(this.simulacion, 'Distribucion', aux);

            return true

        },


        updateParticipacion(distribucionItem: any) {
            distribucionItem.ElectoresPorParticipacion = this.electoresSobrePArticipacion(distribucionItem)
        },

        updateVotos(fuerza: any, totalElectores: number) {
            console.log(((totalElectores * parseFloat(fuerza.PorcentajeSimulacion)) / 100).toLocaleString('es-AR', {
                minimumFractionDigits: 0, // Aseguramos siempre 2 decimales
                maximumFractionDigits: 0  // Máximo de 2 decimales
            }));
            fuerza.Ganadas = ((totalElectores * parseFloat(fuerza.PorcentajeSimulacion)) / 100).toLocaleString('es-AR', {
                minimumFractionDigits: 0, // Aseguramos siempre 2 decimales
                maximumFractionDigits: 0  // Máximo de 2 decimales
            })

        },

        electoresSobrePArticipacion(distribucion: any) {
            return ((distribucion.Electores * distribucion.Participacion) / 100).toLocaleString('es-AR', {
                minimumFractionDigits: 0, // Aseguramos siempre 2 decimales
                maximumFractionDigits: 0  // Máximo de 2 decimales
            })
        },

        formatNumber(number: number) {
            // Convertimos la cadena a un número con decimales (si corresponde)
            return number.toLocaleString('es-AR', {
                minimumFractionDigits: 0, // Aseguramos siempre 2 decimales
                maximumFractionDigits: 2  // Máximo de 2 decimales
            });
        },

        downloadReport() {
            // alert('Reporte en desarrollo')
            this.loadReport = true;
            this.$store.dispatch("setSimtoPrint", this.simulacion);
            let aux = router.resolve({name: "Informe"}).href
            // this.$refs.informe.generateReport()
            window.open(aux, '_blank');
            this.loadReport = false;
            
        },

        done(event: any) {
            this.loadReport = false;
            // console.log("done", event);
            // this.$refs.informe.$destroy()
        },


        handleScroll(event: any) {
            this.viewBtAcction = window.scrollY > 200
        },

        topev() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
        ,
        async validate() {
            let result = await this.$refs.usuario.validate();
            return result;
        },
        async initializeEdit(idsimulacion: string) {
            this.modeEdit = this.$route.params.idsimulacion.toString() != "nuevo"
            this.reloadCamaras = false
            let resu = await simulacionService.one(idsimulacion);
            if (resu) {
                this.reloadCamaras = true
            }
            this.simulacion = resu.data
        },
        async initializeAdd(Id: string) {
            this.modeEdit = this.$route.params.idsimulacion.toString() != "nuevo"
            let resu = await simulacionService.oneCamara(Id);
            this.simulacion = { ...this.simulacion, ...resu.data, _id: 0 }
            this.simulacion.Distribucion.map((d: any) => {
                d.ElectoresPorParticipacion = 0
                d.Fuerzas.map((f: any) => {
                    f.PorcentajeSimulacion = f.Porcentaje
                    f.Ganadas = 0
                })
            })

            if (!resu.data._id) {
                this.simulacion = { ...this.simulacion, ...this.simulacionBase }
            }
        },
        validateValueFuerza(item: any) {
            if (item.Fuerza == '') {
                item.Fuerza = 'Otras'
            }
            return true
        },
        changeAmbito() {
            this.reloadCamaras = false
            this.initializeAdd(this.simulacion.Id);
            setTimeout(() => {
                this.reloadCamaras = true
            }, 1000)

        },
        openDialogSaveSimulacion() {
            this.dialog = true
        },
        openDialogParticipacionBase() {
            this.dialogParticipacion = true
        },
        eventSaveSimulacion() {
            this.simulation();
            this.saveSimulacion()
        },
        async saveSimulacion() {
            if (this.$route.params.idsimulacion.toString() == "nuevo") {
                delete this.simulacion._id
                let res = await simulacionService.add(this.simulacion);
                this.$router.push("/simulacion/" + res.data._id);
                this.initializeEdit(this.$route.params.idsimulacion.toString());
                this.dialog = false
            } else {
                await simulacionService.edit(this.simulacion._id, this.simulacion);
                this.dialog = false
            }
        },

        /**
         * 
         * @Params {number} index de la fuerza a obtener el resultado
         * @Params {number} index de la fuerza a obtener el resultado
         * 
         * 
         * @Returms {number}
         */
        getobtenidas(distribucion: number, fuerza: number) {
            if (this.simulacion.Distribucion.length) {
                console.log(this.simulacion.Distribucion[distribucion].Fuerzas[fuerza]);

                return this.simulacion.Distribucion[distribucion].Fuerzas[fuerza].Renueva
            } else {
                return 0
            }
        },

        /**
         * Realiza la simulacion de todas las distribuciones que tiene
         * 
         * @returms void 
         */
        async makeSimulations() {

            let error = false
            this.simulacion.Distribucion.map((d: any) => {
                if (isNaN(d.Fuerzas[0].PorcentajeSimulacion) || isNaN(d.Fuerzas[1].PorcentajeSimulacion) || isNaN(d.Fuerzas[2].PorcentajeSimulacion)) {
                    error = true
                    return
                }
            })
            if (!error) {
                this.reloadCamaras = false
                await Promise.all(this.simulacion.Distribucion.map(async (d: any) => {
                    switch (this.simulacion.Id) {
                        case 1:
                            await this.simular(d)
                            break
                        case 2:
                            await this.simularSenedoresNacionales(d)
                            break
                        case 3:
                            await this.simularSenadoresDiputadosProvBuenosAires(d)
                            break
                        case 4:
                            await this.simularSenadoresDiputadosProvBuenosAires(d)
                            break
                    }
                    return d
                })) && (this.reloadCamaras = true)

            } else {
                this.setNotification({
                    title: "Error",
                    message:
                        "Error al realizar la simulación, distribución con porcentaje mayor a 100%",
                    timeout: "6000",
                    color: "error",
                });
            }
            return !error
        },

        /**
         * 
         * @param distribucionItem Distribucion 
         */
        async simular(distribucionItem: any) {
            /** This is the class require. */

            /* eslint-disable */
            const { Dhondt } = require('dhondt-calculator');

            let cantidadvotostotal = Number(((distribucionItem.Electores * distribucionItem.Participacion) / 100).toFixed())

            let cantidadvotosBlanco = ((cantidadvotostotal * distribucionItem.Blancos) / 100).toFixed()

            const votes = distribucionItem.Fuerzas.map((fuerzas: any) => Number(((cantidadvotostotal * fuerzas.PorcentajeSimulacion) / 100).toFixed()));
            const names = distribucionItem.Fuerzas.map((fuerzas: any) => fuerzas.Fuerza);

            const options = {
                mandates: Number((distribucionItem.Fuerzas.reduce((fuerzasAculador: any, fuerzas: any) => fuerzasAculador + fuerzas.Renueva, 0)).toFixed()),
                blankVotes: Number(cantidadvotosBlanco),
                percentage: 3
            };

            /** This is the instance */
            const dhondt = new Dhondt(votes, names, options);

            const result = await dhondt.computeWithPromise();

            const distrito = this.simulacion.Distribucion.find((d: any) => d.IdDistrito == distribucionItem.IdDistrito);

            distrito.Fuerzas.map((f: any) => {
                f.Ganadas = isNaN(result.parties[f.Fuerza]) ? 0 : result.parties[f.Fuerza]
            })
        },

        calcularPocercentajeSimulacion(Fuerzas: any) {
            Fuerzas[3].PorcentajeSimulacion = (100.00 - (Number(Fuerzas[0].PorcentajeSimulacion) + Number(Fuerzas[1].PorcentajeSimulacion))) > 0 ? ((100.00 - (Number(Fuerzas[0].PorcentajeSimulacion) + Number(Fuerzas[1].PorcentajeSimulacion))).toFixed(2)) : "ERROR"
        },

        showItem(distribucionItem: any) {
            // return (distribucionItem.Fuerzas[0].Renueva != 0 || distribucionItem.Fuerzas[1].Renueva != 0)
            return true
        },

        simularSenedoresNacionales(distribucionItem: any) {
            if (this.showItem(distribucionItem)) {
                let fuerzasCopia = [...distribucionItem.Fuerzas]
                fuerzasCopia.sort((a: any, b: any) => b.PorcentajeSimulacion - a.PorcentajeSimulacion)[0].Ganadas = 2
                fuerzasCopia.sort((a: any, b: any) => b.PorcentajeSimulacion - a.PorcentajeSimulacion)[1].Ganadas = 1
                fuerzasCopia.sort((a: any, b: any) => b.PorcentajeSimulacion - a.PorcentajeSimulacion)[2].Ganadas = 0
                fuerzasCopia.sort((a: any, b: any) => b.PorcentajeSimulacion - a.PorcentajeSimulacion)[3].Ganadas = 0
                for (let index = 0; index < distribucionItem.Fuerzas.length; index++) {
                    distribucionItem.Fuerzas[index].Ganadas = fuerzasCopia.find(fc => fc.Fuerza == distribucionItem.Fuerzas[index].Fuerza).Ganadas
                }
            }
        },

        /**
         * 
         * @param distribucionItem Distribucion 
         */
        async simularSenadoresDiputadosProvBuenosAires(distribucionItem: any) {

            if (this.showItem(distribucionItem)) {

                let cantidadvotostotal = Number(((distribucionItem.Electores * distribucionItem.Participacion) / 100).toFixed())

                let cantidadvotosBlanco = ((cantidadvotostotal * distribucionItem.Blancos) / 100).toFixed()

                const fuerzasVotes = distribucionItem.Fuerzas.map((fuerzas: any) => {
                    return {
                        votes: Number(((cantidadvotostotal * fuerzas.PorcentajeSimulacion) / 100).toFixed()),
                        ...fuerzas
                    }
                });
                // console.log("🚀 ~ file: Simulacion.vue:648 ~ simularDiputadosProvBuenosAires ~ votes", fuerzasVotes)

                const cantidadDeMandatos = Number((distribucionItem.Fuerzas.reduce((fuerzasAculador: any, fuerzas: any) => fuerzasAculador + fuerzas.Renueva, 0)).toFixed())
                // console.log("🚀 ~ file: Simulacion.vue:651 ~ simularDiputadosProvBuenosAires ~ cantidadDeMandatos", cantidadDeMandatos)

                const cociente = (fuerzasVotes.reduce((fuerzasAculador: any, fuerzas: any) => fuerzasAculador + fuerzas.votes, 0)) / cantidadDeMandatos
                // console.log("🚀 ~ file: Simulacion.vue:654 ~ simularDiputadosProvBuenosAires ~ cociente", cociente)

                let fue = fuerzasVotes.map((fuerzas: any) => {
                    // console.log(fuerzas.Fuerza);
                    // console.log(fuerzas.votes / cociente);
                    fuerzas['Ganadas'] = Math.trunc(fuerzas.votes / cociente)
                    fuerzas['Resto'] = (fuerzas.votes / cociente).toString().split(".")[1]

                    // console.log("🚀 ~ file: Simulacion.vue:661 ~ fue ~ fuerzas.votes", fuerzas.votes)
                    // console.log("🚀 ~ file: Simulacion.vue:662 ~ fue ~ cociente", cociente)
                    // console.log("🚀 ~ file: Simulacion.vue:661 ~ fue ~ Math.trunc(fuerzas.votes / cociente)", fuerzas.votes / cociente)
                })
                //  console.log("🚀 ~ file: Simulacion.vue:675 ~ fue ~ fuerzasVotes", fuerzasVotes)

                let cantidadElectoresRestantes = cantidadDeMandatos - fuerzasVotes.reduce((fuerzasAculador: any, fuerzas: any) => fuerzasAculador + fuerzas.Ganadas, 0)
                // console.log("🚀 ~ file: Simulacion.vue:678 ~ simularDiputadosProvBuenosAires ~ cantidadElectoresRestantes", cantidadElectoresRestantes)

                let fuerzasCopia = [...fuerzasVotes]

                fuerzasCopia = fuerzasCopia.filter(f => {
                    return f.Ganadas > 0
                })

                fuerzasCopia.sort((a: any, b: any) => {
                    return b.Resto - a.Resto
                })

                if (fuerzasCopia.length > 0) {
                    let pos = 0
                    while (cantidadElectoresRestantes > 0) {
                        fuerzasCopia[pos]['Ganadas'] = fuerzasCopia[pos]['Ganadas'] + 1
                        cantidadElectoresRestantes = cantidadElectoresRestantes - 1
                        if (cantidadElectoresRestantes > 0) {
                            pos = pos + 1
                        }
                        if (pos == fuerzasCopia.length) {
                            pos = 0
                        }
                    }
                }

                // console.log("🚀 ~ file: Simulacion.vue:678 ~ simularSenadoresDiputadosProvBuenosAires ~ fuerzasCopia", fuerzasCopia)


                // console.log("🚀 ~ file: Simulacion.vue:680 ~ simularDiputadosProvBuenosAires ~ fuerzasCopia", fuerzasCopia)
                for (let index = 0; index < distribucionItem.Fuerzas.length; index++) {
                    distribucionItem.Fuerzas[index].Ganadas = fuerzasCopia.find(fc => fc.Fuerza == distribucionItem.Fuerzas[index].Fuerza)?.Ganadas || 0
                }
            }

        },
    },

    computed: {



        distribucionesComputed() {
            
            return this.filterDistribucion ?  this.simulacion.Distribucion.filter((d:any) => d.Distrito.toLowerCase().includes(this.filterDistribucion.toLowerCase()))  : this.simulacion.Distribucion
        },

        candidato1() {
            return this.simulacion.Distribucion.map((d: any) => d.Fuerzas.find((f: any) => f.Fuerza == "CFK")).flat()
                .reduce((acc: any, dist: any) => acc + dist.Ganadas, 0)

        },
        candidato2() {
            return this.simulacion.Distribucion.map((d: any) => d.Fuerzas.find((f: any) => f.Fuerza == "QUINTELA")).flat()
                .reduce((acc: any, dist: any) => acc + dist.Ganadas, 0)

        },

        venceMandato() {
            return this.simulacion.Distribucion.map((distribuciones: any) => distribuciones.Fuerzas.map((fuerza: any) => fuerza.Renueva))
                .flat().reduce((distribuciones: any, distribucionesOld: any) => distribuciones + distribucionesOld, 0)
        },

        readonly() {
            // console.log(this.$route.query.readonly);

            return (this.$route.query.readonly) ? Boolean(this.$route.query.readonly) : false
        },


        totalElectores() {

            return this.simulacion.Distribucion.reduce((acum: any, distribucion: any
            ) => acum + distribucion.Electores, 0)

        }

    }

}

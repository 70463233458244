


export default {
    name: 'Candidatos',
    props: {
        candidato1: Number,
        candidato2: Number
    },


    computed: {
        porcentageCandidato1(): number {
            return (this.candidato1 * 100) / (this.candidato1 + this.candidato2)
        },
        porcentageCandidato2(): number {
            return (this.candidato2 * 100) / (this.candidato1 + this.candidato2)
        }
    },



}

